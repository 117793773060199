var error = 'error'; // 错误
var success = 'success'; // 成功
var warning = 'warning'; // 警告

export function emptyRules(val) {
  if (val) {
    return success
  }
  return error
}

export function bankCardRules(val) {
  if (val) {
    // if (/^[0-9]{10,16}$/.test(val) && (val.length == 10 || val.length == 16)) {
    if (/^[0-9]{10}$/.test(val)) {
      return success
    }
    return warning
  }
  return error
}

export function emailRules(val) {
  if (val) {
    if (/^.+@.+$/.test(val)) {
      return success
    }
    return warning
  }
  return error
}

export function nameRules(val) {
  if (val) {
    return success
  }
  return error
}

export function phoneRules(val) {
  if (val) {
    val = val.replace(/\s/g, '')
    if (/^(0|234)[0-9]{10}$/.test(val)) {
      return success
    }
    return warning
  }
  return error
}

export function passwordRules(val) {
  if (val) {
    if (val.length >= 6) {
      return success
    }
    return warning
  }
  return error
}

export function comfirmPasswordRules(val, oldVal) {
  if (val) {
    if (val.length >= 6) {
      if (oldVal != val) {
        return warning
      }
      return success
    }
    return warning
  }
  return error
}

export function validateCodeRules(val) {
  if (val) {
    if (val.length == 4) {
      return success
    }
    return warning
  }
  return error
}

export function idcardRules(val) {
  if (val) {
    if (/^[0-9]{11,11}$/.test(val)) {
      return success
    }
    return warning
  }
  return error
}